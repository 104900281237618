import * as React from 'react'
import styled from 'styled-components'
import { Paper } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import { useMutation, useQuery } from "@apollo/react-hooks"
import gql from 'graphql-tag'
import WaitButton from "../ui/WaitButton"
import { useEffect } from "react"

const Warning = styled.div`
	background-color: red;
	padding: 10px;
	margin-bottom: 10px;
`

const StyledPaper = styled(Paper)`
	padding: 20px;
	margin: 20px;
`

const Result = styled.div`
	padding: 10px;
	margin: 10px;
	border-radius: 10px;
	background-color: lightgray;
	color: black;
`

const StyledDivider = styled(Divider)`
	margin: 10px;
`

const RE_PARSE_PROPERTIES_XML = gql`
	mutation {
		reParsePropertiesXml
	}
`

const GET_RE_PARSE_REQUEST = gql`
	query reParseRequest(
	$reParseRequest: ID!
	){
		reParseRequest (
			reParseRequestId: $reParseRequest
		) {
			successfulProperties
			failedProperties
		}
	}
`

const IdResult = styled.div`
	margin-left: 15px;
	
`

const DevActions = () => {
	const [open, setOpen] = React.useState(false);
	const [inProgress, setInProgress] = React.useState(false);
	const [reParsePropertiesXmlMutation, { data: { reParsePropertiesXml = null } = {}, error: reParseError}] = useMutation(RE_PARSE_PROPERTIES_XML);
	const { loading, error: requestError, data: { reParseRequest = null} = {} } = useQuery(GET_RE_PARSE_REQUEST, {
		variables: { reParseRequest: reParsePropertiesXml },
		skip: !reParsePropertiesXml,
		pollInterval: 500,
	});
	console.log(requestError);
	console.log(reParseError);
	return (
		<StyledPaper>
			<Warning>Only do this if you're sure!!!!!!</Warning>
			<Typography variant={"h4"}>Dev actions</Typography>
			<StyledDivider />
			<Typography variant={"h6"}>Re parse all properties</Typography>
			<Button onClick={() => setOpen(true)}>
				Trigger re-parse
			</Button>
			<Typography>Result</Typography>
			<Result>
				{reParsePropertiesXml && (
					<>
						<div style={{fontWeight: "bold"}}>Re parse request Id:</div>
						<IdResult>{reParsePropertiesXml}</IdResult>
						{reParseRequest && (
							<>
								<div style={{fontWeight: "bold"}}>Successful properties ids:</div>
								{reParseRequest.successfulProperties.map(id => <IdResult key={id}>{id}</IdResult>)}
								<div style={{fontWeight: "bold"}}>Failed properties ids:</div>
								{reParseRequest.failedProperties.map(id => <IdResult key={id}>{id}</IdResult>)}
							</>
						)}
					</>
				)}
			</Result>
			<StyledDivider />
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Re parse properties xml?"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						This will go through ALL the properties and re-parse the xml
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>
						Disagree
					</Button>
					<WaitButton
						inProgress={inProgress}
						onClick={async () => {
							setInProgress(true);
							await reParsePropertiesXmlMutation()
							setInProgress(false);
							return setOpen(false)
						}}
					>
						Agree
					</WaitButton>
				</DialogActions>
			</Dialog>
		</StyledPaper>
	)
}

export default DevActions
