import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button, { ButtonProps } from '@material-ui/core/Button';

interface Props<EventArgs=any, ReturnType=void> {
	disabled?: boolean
	onClick?: (event: EventArgs) => ReturnType
	inProgress?: boolean
	children?: React.ReactNode
	buttonProps?: ButtonProps
	style?: any
	className?: any
}

const defaultButtonProps: ButtonProps = {
	variant: "contained",
	color: "secondary",
	type: "submit"
}

export const WaitButton = ({ disabled, onClick, inProgress, children, buttonProps, style, className }: Props) => {
	const submitProps: ButtonProps = { ...defaultButtonProps, ...buttonProps	}

    return (
        <Container style={style} className={className}>
            <SubmitButton {...submitProps} disabled={disabled || inProgress} onClick={onClick}>{children}</SubmitButton>
            {inProgress && <ProgressIcon />}
        </Container>
    );
}

const Container = styled.div`
    display: inline-block;
    position: relative;
`;

const SubmitButton = styled(Button)`
	padding: 6px 18px;
`

const ProgressIcon = styled(CircularProgress).attrs({
	color: "secondary",
	size: 24
})`
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -12px;
	margin-top: -12px;
`

export default WaitButton;

type ClickHandler<Args = any, Returns = void> = (ev: Args) => Promise<Returns>
interface Options<EventArgs=any, ReturnType=void> extends Props<EventArgs, ReturnType> {
	onReject?: (reason: any) => void
}

export function useWaitButton<EventArgs = any, ReturnType = void>(clickHandler: ClickHandler<EventArgs, ReturnType>, options?: Options<EventArgs, ReturnType>): Props {
	const { onReject, ...props } = (options || { onReject: undefined });

	const [inProgress, setInProgress] = React.useState(false);
	return {
		...props,
		inProgress,
		onClick: async (ev: EventArgs): Promise<void> => {
			if (!clickHandler) { return; }
			try {
				setInProgress(true);
				await clickHandler(ev);
			} catch (err) {
				if (onReject) {
					await onReject(err);
				} else {
					console.error(err);
				}
			} finally {
				setInProgress(false);
			}
		}
	}
}