import React from 'react'
import styled from 'styled-components'
import { List } from '@material-ui/core'

import SidebarItem from '../ui/sidebar/SidebarItem'

interface Props {}

const AdminSidebarItems = ({  }: Props) => {
	return (
		<ItemsContainer>
			<List>
				<SidebarItem name="Jobs" url="/admin" partiallyActive />
				<SidebarItem name="Promos" url="/admin/promos" />
				<SidebarItem name="Job Items" url="/admin/job-items" />
				<SidebarItem name="Users" url="/admin/users" />
				<SidebarItem name="Create Accounts" url="/admin/create-account" />
				<SidebarItem name="Group Deals" url="/admin/groups" />
				<SidebarItem name="Dev actions" url="/admin/dev-actions" />
			</List>
		</ItemsContainer>
	)
}

export default React.memo(AdminSidebarItems)

const ItemsContainer = styled.div`
	padding: 22px 10px;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
`
