import React from 'react'
import styled from 'styled-components'
import { Typography, Button } from '@material-ui/core'
import { unsetLocalToken, getDecodedToken } from '../../utils/client'
import LogRocket from 'logrocket';
import { navigate } from 'gatsby';

interface Props {
  homeLink?: string
  children?: any
}

interface State {
  hasError: boolean
  errorInfo?: Error
}

export default class DisastrousErrorHandler extends React.Component<Props, State> {
  state: State = { hasError: false, errorInfo: undefined }

  componentDidCatch = (e) => {
    if (process.env.GATSBY_LOGROCKET_ACTIVE === 'true') {
      LogRocket.error(e)
    }
    console.warn(e)
    this.setState({hasError: true, errorInfo: e})
  }

  _logout = () => {
    unsetLocalToken();
    navigate('/');
    setTimeout(() => location.reload(), 0)
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    const token = getDecodedToken()
    const show_more_info = token && ((token as any).isGhostedAdmin || (token as any).role === 'ADMIN')

    return (
      <Outer>
        <Typography variant="h4" gutterBottom>An Error Occurred</Typography>
        <Typography variant="subtitle1">We ran into a problem fetching your data</Typography>
        {show_more_info && JSON.stringify(this.state.errorInfo)}
        <Buttons>
          <Button onClick={this._logout}>Go To Login</Button>
          {this.props.homeLink && <Button href={this.props.homeLink}>Go Home</Button>}
        </Buttons>
      </Outer>
    )
  }
}

const Outer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > * {
      color: white;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0 8px;
  }
`
