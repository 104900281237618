import React from 'react'
import { getDecodedToken } from '../../utils/client'
import { navigate } from '@reach/router'

type roles = 'ADMIN' | 'CONTRACTOR' | 'HOMEOWNER'

interface Props {
  children: any
  allowed: roles | roles[]
}

const AuthGuard = ({allowed, children}: Props) => {
  React.useEffect(() => {
    const token = getDecodedToken()
    const role = (token as any).role as roles
    if ((typeof allowed === 'string' && role !== allowed ) || (typeof allowed !== 'string' && !allowed.includes(role))) {
      if (!sessionStorage.lastRedir || parseInt(sessionStorage.lastRedir) < Date.now() - 1e3) {
        sessionStorage.lastRedir = Date.now()
        navigate('/')
      }
    }
  }, [])

  return children
}

export default AuthGuard