import React from 'react'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler';
import AuthGuard from '../../components/ui/AuthGuard';
import AdminSidebar from '../../components/adminSidebar';
import DevActions from '../../components/admin/DevActions';

const AdminMain = () => (
  <DisastrousErrorHandler>
    <AuthGuard allowed="ADMIN">
	<AdminSidebar>
		<DevActions />
	</AdminSidebar>
    </AuthGuard>
  </DisastrousErrorHandler>
);

export default AdminMain;
