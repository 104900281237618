import React from 'react'
import '../layout/layout.css'
import StaticDrawerWrapper from '../ui/sidebar/staticDrawerWrapper'

import SidebarJobs from './SidebarJobs'

const AdminSidebar = ({ children }: { children: React.ReactNode }) => {
	return (
		<StaticDrawerWrapper
			sideArea={
				() => (
						<SidebarJobs />
				)
			}
		>
			{children}
		</StaticDrawerWrapper>
	)
}

export default AdminSidebar
